<template>
  <Dialog ref="dialog" :config="config" :visible='visible' :cancelDialog="cancelDialogs" :confirmDialog='importConfirm' :isdeling='isdeling'>
    <priview-pdf ref="pdf" :pdfUrl="fileUrl" ></priview-pdf>
    <!-- <div class="main-section" id="content" ref="contentDiv" v-loading='loading'></div>-->
    <div class="bottom_info">
      <span>存储位置：增值税销项电子发票目录/{{year}}年/{{month}}月</span>
      <el-button type="primary" class="download" @click="batchDownLoad" >下载</el-button>
    </div>
  </Dialog>
</template>

<script>
  import Dialog from "@/components/Dialog";
  // import PriviewPdf from "@/components/PriviewPdf";
  // import {parseOfdDocument, renderOfd} from "ofd.js";
  import axios from 'axios';

  export default {
    props: {
      title: {
        type: String,
      },
      visible: {
        type: Boolean,
      },
      cancelDialog: {
        type: Function,
        default: () => { },
      },
      itemId: {
        type: String,
      }
    },
    components: {
      Dialog,
    },
    data() {
      return {
        // pdfUrl: 'http://storage.xuetangx.com/public_assets/xuetangx/PDF/PlayerAPI_v1.0.6.pdf',
        // pdfUrl: '/static/test.pdf',
        // pdfUrl: '/static/test.ofd',

        fileUrl: '',
        config: {
          top: '10vh',
          width: '46rem',
          type: 'PDF',
          title: '042002000211_60278288_20201214.pdf',
          center: true,
          btnTxt: [],
        },
        year: '',
        month: '',
        fileList: [],
        fileData: '',
        isdeling: false,
        tableData: [],
        status:  false,
        message : '请选择上传文件',
        screenWidth :'840',
        loading : false,
      };
    },
    methods: {
      cancelDialogs(){
        this.cancelDialog();
      },
      importConfirm(){
        this.cancelDialog();
      },
      // 下载pdf文件
      batchDownLoad(){
        var fileName = this.title
        axios({
          method: 'get',
          url: '/api/einvoice/einv/download',
          responseType: 'blob',
          params: {
            id:this.itemId,
          },
          headers: {
            'Content-Type': "application/json;charset=utf-8",
            'Authorization': this.getCookies('Authorization'),
            'group' :process.env.VUE_APP_GROUP
          }
        }).then((res) => {
          let data = res.data
          // let url = window.URL.createObjectURL(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))
          let url = window.URL.createObjectURL(new Blob([data], { type: "application/pdf;chartset=UTF-8" }))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url;
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          this.exportLoading = false
        })
      },
    },
    
    // mounted(){
    //   this.getOfdDocumentObj(this.fileUrl, this.screenWidth);
    // },
    watch:{
      title(val){
        if(val){
          this.config.title = this.title
        }
      },
    }
  };
</script>

<style lang="less" scoped>
  .firstStep {
    margin: 24px 0 12px;
  }
  .upload {
    cursor: pointer;
    color: #4f71ff;
  }
  .main-section{
    min-height: 500px;
  }
  .bottom_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 38px;
    margin: 4px 0 24px;
  }
  .download {
    width: 100px;
    height: 40px;
    padding: 0;
    font-size: 16px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    // color: #ffffff;
  }
</style>
